import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, useTheme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, list, theme) {
    return {
      fontWeight:
        list.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}


export function SetDefaultAllocatedHoursButton({ clientId }) {
    let [client, setClient] = useState(null);
    let [hours, setHours] = useState(0);

    let [dialogOpen, setDialogOpen] = useState(false);
    let [snackbarOpen, setSnackbarOpen] = useState(false);
    let [snackbarMessage, setSnackbarMessage] = useState("");
    let [requestInProgress, setRequestInProgress] = useState(false);

    useEffect(() => {
        fetch("/api/v1/account/client/" + clientId).then((res) => {
            return res.json();
        }).then((clientRes) => {
            setClient(clientRes);
            setHours(clientRes.defaultAllocatedHours);
        });
    }, []);

    function canSubmitDefaultAllcoatedHours() {
        return (client != null) && (hours != client.defaultAllocatedHours) && (hours >= 0);
    }

    function submitSetDefaultAllocatedHours() {
        setRequestInProgress(true);
        fetch("/api/v1/account/set-default-allocated-hours/" + clientId + "/" + hours, {
            method: "POST"
        }).then((res) => {
            if(res.status == 200) {
                setSnackbarMessage("Default allocated hours updated successfully");
                setSnackbarOpen(true);
                setDialogOpen(false);
                client.defaultAllocatedHours = hours;
            } else {

                setSnackbarMessage("Error while updating allocated hours with code: " + res.status);
                setSnackbarOpen(true);
            }
            setRequestInProgress(false);
        });
    }

    return (
        <React.Fragment>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} />
            <Modal open={dialogOpen} onClose={() => { setDialogOpen(false) }}>
                <ModalDialog>
                    <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "100%", mt: "10px" }} spacing={ 1 }>
                        <TextField
                            autoFocus
                            required
                            type="number"
                            margin="dense"
                            label="Number of hours"
                            fullWidth
                            variant="standard"
                            value={hours}
                            onChange={(event) => setHours(Number(event.target.value))}
                        />
                        <Stack direction="row" spacing={0.25}>
                            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                            <Button disabled={!canSubmitDefaultAllcoatedHours() || requestInProgress} loading={requestInProgress} onClick={() => submitSetDefaultAllocatedHours()}>Submit</Button>
                        </Stack>
                    </Stack>
                </ModalDialog>
            </Modal>
            { client != null && <Chip label="Edit Default Hours" onClick={() => { setDialogOpen(true) }} color="primary" icon={<AccessTimeIcon />} />}
        </React.Fragment>
    );
}

export function CreateLaborCategoriesButton({ clientId }) {
    const theme = useTheme();
    let [laborCategoryModalOpen, setLaborCategoryModalOpen] = useState(false);
    let [coachCertLevels, setCoachCertLevels] = useState([]);
    let [laborCategories, setLaborCategories] = useState([]);

    let [isAddingLaborCategory, setIsAddingLaborCategory] = useState(false);
    let [newLaborCategoryName, setNewLaborCategoryName] = useState('');
    let [newLaborCategoryBillingRate, setNewLaborCategoryBillingRate] = useState(0.00);
    let [newLaborCategoryCoachLevels, setNewLaborCategoryCoachLevels] = useState([]);
    
    let [requestInProgress, setRequestInProgress] = useState(false);
    let [requestSnackbarOpen, setRequestSnackbarOpen] = useState(false);
    let [requestSnackbarMessage, setRequestSnackbarMessage] = useState('');

    useEffect(() => {
        fetch("/api/v1/account/labor-categories/" + clientId).then((res) => {
            return res.json();
        }).then((laborCategoriesResponse) => {
            for(let i = 0; i < laborCategoriesResponse.laborCategories.length; i++) {
                laborCategoriesResponse.laborCategories[i].existing = true;
                laborCategoriesResponse.laborCategories[i].coachLevelRelations = [];
                for(let j = 0; j < laborCategoriesResponse.laborCategoryRelations.length; j++) {
                    if(laborCategoriesResponse.laborCategoryRelations[j].laborCategoryId == laborCategoriesResponse.laborCategories[i].laborCategoryId) {
                        laborCategoriesResponse.laborCategories[i].coachLevelRelations.push(laborCategoriesResponse.laborCategoryRelations[j]);
                    }
                }
            }
            setLaborCategories(laborCategoriesResponse.laborCategories);
        });
        fetch("/api/v1/account/coach-certification-levels").then((res) => {
            return res.json();
        }).then((coachCertificationLevelsResponse) => {
            setCoachCertLevels(coachCertificationLevelsResponse.certificationLevels);
        });
    }, []);

    function createLaborCategoriesRequest() {
        let setLaborCategoriesRq = {laborCategories: [], laborCategoryToCoachLevelRelations: []};

        let lcat = {
            laborCategoryName: newLaborCategoryName,
            billingRate: parseInt(newLaborCategoryBillingRate.replace(".", "")) * 100
        };
        setLaborCategoriesRq.laborCategories.push(lcat);

        let lcatCoachRelations = newLaborCategoryCoachLevels;
        let relationRq = {
            laborCategoryName: newLaborCategoryName,
            coachCertificationLevelIds: []
        };
        for(const relationCertName of lcatCoachRelations) {
            let cert = coachCertLevels.find((ccl) => { return ccl.name == relationCertName});
            relationRq.coachCertificationLevelIds.push(cert.certificationId);
        }
        setLaborCategoriesRq.laborCategoryToCoachLevelRelations.push(relationRq);
        return setLaborCategoriesRq;
    }

    function canSubmitCreateLaborCategoryRequest() {
        return true;
    }

    function submitCreateLaborCategoryRequest() {
        let setLaborCategoriesRq = createLaborCategoriesRequest();
        setRequestInProgress(true);
        fetch("/api/v1/account/set-labor-categories/" + clientId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(setLaborCategoriesRq)
        }).then((res) => {
            if(res.status == 200) {
                setRequestSnackbarMessage('New labor category created successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } else {
                setRequestSnackbarMessage('Error while creating labor category with error code: ' + res.status);
            }
            setRequestSnackbarOpen(true);
            setRequestInProgress(false);
        });
    }

    function getCoachCertLevelById(id) {
        for(let i = 0; i < coachCertLevels.length; i++) {
            if(coachCertLevels[i].certificationId == id)
                return coachCertLevels[i];
        }
        return null;
    }

    function formatBillingRate(br) {
        return "$" + (br / 100.0).toFixed(2);
    }

    function getFormattedCoachLevelRelations(laborCategory) {
        if(laborCategory.coachLevelRelations.length == 0) {
            return "None";
        } else if(laborCategory.coachLevelRelations.length == 1) {
            let cert = getCoachCertLevelById(laborCategory.coachLevelRelations[0].coachCertificationLevelId);
            if(cert != null)
                return cert.name;
            return "ERR";
        } else {
            let certNameList = [];
            for(let i = 0; i < laborCategory.coachLevelRelations.length; i++) {
                let cert = getCoachCertLevelById(laborCategory.coachLevelRelations[i].coachCertificationLevelId);
                if(cert != null)
                    certNameList.push(cert.name);
            }
            return certNameList.join(", ");
        }
    }

    if(coachCertLevels.length == 0)
        return;
    return (
        <React.Fragment>
            <Snackbar open={requestSnackbarOpen} autoHideDuration={6000} onClose={() => setRequestSnackbarOpen(false)} message={requestSnackbarMessage} />
            <Modal open={laborCategoryModalOpen} onClose={() => { setLaborCategoryModalOpen(false) }}>
                <ModalDialog>
                    <ModalClose />
                    <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "100%", mt: "18px" }} spacing={ 1 }>
                        <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                            <TableContainer sx={{ overflowx: "initial" }}>
                                <Table sx={{ minWidth: "900px" }} stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Labor Category</StyledTableCell>
                                        <StyledTableCell>Billing Rate (USD)</StyledTableCell>
                                        <StyledTableCell>Required Coach Certifications</StyledTableCell>
                                    </TableHead>
                                    <TableBody>
                                        {laborCategories.map((lcat, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell>{lcat.existing == false && "DELETE"}</StyledTableCell>
                                                    <StyledTableCell>{lcat.laborCategoryName}</StyledTableCell>
                                                    <StyledTableCell>{formatBillingRate(lcat.billingRate)}</StyledTableCell>
                                                    <StyledTableCell>{getFormattedCoachLevelRelations(lcat)}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                            {isAddingLaborCategory && 
                                                <React.Fragment>
                                                    <StyledTableRow key='editor'>
                                                        <StyledTableCell></StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextField
                                                              autoFocus
                                                              required
                                                              margin="dense"
                                                              label="Name"
                                                              fullWidth
                                                              variant="standard"
                                                              value={newLaborCategoryName}
                                                              onChange={(e) => setNewLaborCategoryName(e.target.value)} />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextField
                                                              autoFocus
                                                              required
                                                              type="number"
                                                              margin="dense"
                                                              label="Billing Rate"
                                                              fullWidth
                                                              variant="standard"
                                                              value={Number(newLaborCategoryBillingRate).toFixed()} 
                                                              onChange={(event) => {setNewLaborCategoryBillingRate(Number(event.target.value).toFixed())}} 
                                                              startAdornment={<InputAdornment position="start">$</InputAdornment>} />    
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <FormControl sx={{ width: 300 }}>
                                                                <FormLabel id="coachLevelFormLabel">Coach Certification Level(s)</FormLabel>
                                                                <Select 
                                                                  multiple
                                                                  labelId="coachLevelFormLabel" 
                                                                  value={newLaborCategoryCoachLevels} 
                                                                  onChange={(event) => {setNewLaborCategoryCoachLevels(typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value)}}
                                                                  input={<OutlinedInput label="Name" />}
                                                                  menuProps={MenuProps}>
                                                                    {coachCertLevels.map((certification) => {
                                                                        return(
                                                                            <MenuItem key={certification.name} value={certification.name} style={getStyles(certification.name, newLaborCategoryCoachLevels, theme)}>
                                                                                {certification.name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <StyledTableCell colSpan={2}><Button onClick={(e) => {setNewLaborCategoryName(''); setNewLaborCategoryCoachLevels([]); setNewLaborCategoryBillingRate(0.00); setLaborCategoryModalOpen(false);}}>Cancel</Button></StyledTableCell>
                                                        <StyledTableCell colSpan={2}><Button loading={ requestInProgress } loadingPosition='left' disabled={!canSubmitCreateLaborCategoryRequest() || requestInProgress} variant="contained" onClick={(e) => submitCreateLaborCategoryRequest()}>Create</Button></StyledTableCell>
                                                    </StyledTableRow>
                                                </React.Fragment>
                                            }
                                            {!isAddingLaborCategory &&
                                                <React.Fragment>
                                                    <StyledTableRow key='editor'>
                                                        <StyledTableCell colSpan={4}><Button onClick={() => setIsAddingLaborCategory(true)}>Create Labor Category</Button></StyledTableCell>
                                                    </StyledTableRow>
                                                </React.Fragment>
                                            }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {/* Add lcat fields here */}
                    </Stack>
                </ModalDialog>
            </Modal>
            <Chip label="Add Labor Category" onClick={() => { setLaborCategoryModalOpen(true) }} color="primary" icon={<AddIcon />} />
        </React.Fragment>
    );
}